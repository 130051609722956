<template>
  <v-container fluid>
    <p class="text-h4">{{ $t("imprint.title") }}</p>
    <p class="text-body-1">Impressum & Offenlegung gemäß § 25 Mediengesetz</p>
    <p class="text-body-1">
      Übergeordnetes Projekt: FWF Spezialforschungsbereich (F 60) „Deutsch in
      Österreich: Variation – Kontakt – Perzeption“
    </p>
    <p class="text-body-1">
      Standorte: Universität Wien (Institut für Germanistik, Institut für
      Slawistik, Zentrum für Translationswissenschaft) Universität Graz
      (Institut für Germanistik), Universität Salzburg (Fachbereich
      Germanistik), Österreichische Akademie der Wissenschaften (Institut für
      Schallforschung)
    </p>
    <p class="text-body-1">
      Citizen-Science-Projekt: IamDiÖ - Erforsche Deutsch in Österreich
    </p>
    <p class="text-body-1">Projektleiter: Univ.-Prof. Mag. Dr. Gerhard Budin</p>
    <p class="text-body-1">
      Universität Wien <br />
      Zentrum für Translationswissenschaft<br />
      Gymnasiumstraße 50<br />
      A-1190 Wien<br />
    </p>
    <p class="text-body-1">T: +43-1-4277-58020</p>
    <p class="text-body-1">Webredaktion:  ideen@dioe.at</p>

    <p class="text-h4">EU-Streitschlichtung </p>
    <p class="text-body-1">Angaben zur Online-Streitbeilegung: Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank"> https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE</a>. Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>
  
    <p class="text-h5">Haftungshinweis  </p>
  <p class="text-body-1">Für die Inhalte auf diesen Seiten veröffentlichter Einträge sind die entsprechenden Autorinnen und Autoren verantwortlich, ihnen obliegt einerseits die Einhaltung der “Regeln der guten wissenschaftlichen Praxis“ (siehe z.B <a href="http://www.univie.ac.at/studium/gute-wissenschaftliche-praxis/" target="_blank"> http://www.univie.ac.at/studium/gute-wissenschaftliche-praxis/</a>), insbesondere die Achtung des Urheberrechts, andererseits liegt bei ihnen das Urheberrecht für ihre Dokumente.</p>
  <p class="text-body-1">Sollten Sie Bedenken zu bestimmten Inhalten haben, melden sie den Beitrag oder schreiben Sie eine E-Mail an die Webredaktion, welche den Fall überprüfen und entsprechende Inhalte ggf. von den Webseiten entfernen wird.</p>
    <p class="text-h5">Disclaimer - Beiträge   </p>
  
  <p class="text-body-1">Die in den Beiträgen veröffentlichte Meinung spiegelt nicht unbedingt die Meinung aller Mitglieder des Spezialforschungsbereichs „Deutsch in Österreich. Variation – Kontakt – Perzeption“ bzw. dem Projekt "IamDiÖ - Deutsch in Österreich! " wider. </p>
  <p class="text-body-1">Für Beiträge, Fragen und Kommentare gelten folgende Regeln:</p>
 <ul class="text-body-1">
  <li>Die Verantwortung für die geposteten Inhalte liegt bei den Nutzer*innen selbst. </li>
  <li>Für Links zu Seiten Dritter übernehmen wir keine Verantwortung.</li>
  <li>Beachtet, dass Einträge, Fragen und Kommentare öffentlich sind und keine persönlichen Angaben, die nicht für die Öffentlichkeit bestimmt sind, gemacht werden sollten.</li>
  <li>Beiträge sollen zum Thema der Diskussion passen.</li>
  <li>Kommentare sollen in einem respektvollen Ton verfasst sein, damit die Diskussion in einem angenehmen Umfeld stattfindet.</li>
  <li>Wir behalten uns vor, Beiträge, die gegen unsere Richtlinien verstoßen, ohne Angabe von Gründen zu entfernen oder Nutzer*innen zu sperren. Dies gilt insbesondere für Inhalte, die: </li>
  <ul>
    <li>strafbar und gesetzeswidrig sind,</li>
  <li>nur zum Ziel haben, eine Person oder eine Personengruppe schlecht zu machen,</li>
  <li>sexistisch, rassistisch, menschenverachtend, pornografisch, obszön, verleumdend oder diskriminierend sind,</li>
  <li>Androhungen oder Aufforderungen zu Gewalt oder Gewaltverherrlichung enthalten,</li>
  <li>Inhalte, die Urheberrechte, Herstellerrechte, Nutzungsrechte oder Persönlichkeitsrechte Dritter verletzen,</li>
  <li>Werbung, kommerzielle Inhalte, Spam oder Kontaktanzeigen enthalten,</li>
  <li>persönliche Angriffe, pauschale oder persönliche Herabwürdigungen oder Beleidigungen enthalten.</li>

</ul>
</ul>
  <p class="text-body-1">Bis auf Widerruf können wir die Inhalte (unter Nicknamensnennung oder anonymisiert) auch außerhalb der Websiten und Social Media Seiten zitieren und verwenden. 
Verstöße und Widerruf bitte via Melden-Funktion oder Mail melden: ideen@dioe.at.
</p>
<p class="text-h5">Bildnachweis & Urheberrechte </p>
  <ul class="text-body-1">
    <li>Header- und Footerbilder: CC 4.0 by (erstell von Arnold Graf)</li>
</ul>
  </v-container>
</template>
<script>
export default {
  name: "Imprint",
};
</script>

<style scoped>
</style>